
import App from 'components/App/App';
import SwappingIntlProvider from 'components/UI/SwappingIntlProvider/SwappingIntlProvider';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import createRootReducer from 'store/reducers/index';
import './index.css';
import firebase from 'api/firebase/firebase';
import { unregister } from './registerServiceWorker';
import packageInfo from '../package.json';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const history = createBrowserHistory();
firebase.init();

const store = createStore(
	createRootReducer(history),
	composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware(history))),
);

try {
	if (window.location.hostname !== 'localhost') {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_CLIENT_ID,
			tracesSampleRate: 0.1,
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 0.5,
			debug: false,
			integrations: [
				Sentry.browserTracingIntegration(),
				new captureConsoleIntegration({ levels: ['error'] }),
				Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
			],
			release: packageInfo.version,
			ignoreErrors: [
				'Non-Error exception captured',
				'Non-Error promise rejection captured',
			],
		});
		console.log('Sentry initialized');
	}
} catch (error) {
	console.error('Failed to initialize Sentry:', error);
}


const app = (
	<Provider store={store}>
		<SwappingIntlProvider>
			<ConnectedRouter history={history}>
				<Route>
					<App />
				</Route>
			</ConnectedRouter>
		</SwappingIntlProvider>
	</Provider>
);

const root = createRoot(document.getElementById('root'));
root.render(app);

unregister();

export default store;
