import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import axios from 'axios';
// We assume debugTimeStart, debugTimeEnd are local utilities for timing
import { debugTimeStart, debugTimeEnd } from 'utils/utils';

let customizationObjects = [];

/**
 * Initializes Firebase with given config.
 */
function init() {
  // Start timing init
  debugTimeStart('init');
  try {
    const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    firebase.initializeApp(firebaseConfig);
  } finally {
    // End timing init
    debugTimeEnd('init');
  }
}

/**
 * Signs the user out from Firebase.
 * @returns {Promise<void>}
 */
function signOut() {
  debugTimeStart('signOut');
  return new Promise((resolve, reject) => {
    firebase.auth().signOut()
      .then(() => resolve())
      .catch((error) => reject(error));
  }).finally(() => {
    debugTimeEnd('signOut');
  });
}

/**
 * Gets the available languages from the 'locale' node in Realtime Database.
 * @returns {Promise<any>}
 */
function getAvailableLanguages() {
  debugTimeStart('getAvailableLanguages');
  return firebase
    .database()
    .ref('locale')
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getAvailableLanguages');
    });
}

/**
 * Gets the user's token to use in API requests (Refresh token).
 * @returns {Promise<string | null>}
 */
function getUserAPIBearer() {
  debugTimeStart('getUserAPIBearer');
  return new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    if (user && user.isAnonymous === false) {
      resolve(user.refreshToken);
    } else if (user && user.isAnonymous) {
      resolve(null);
    } else {
      reject('No user is currently authenticated');
    }
  }).finally(() => {
    debugTimeEnd('getUserAPIBearer');
  });
}

/**
 * Fetches translations for a specific locale.
 * @param {string} locale
 * @returns {Promise<any>}
 */
function getTranslations(locale) {
  debugTimeStart('getTranslations');
  return firebase
    .database()
    .ref(`translations/${locale}`)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getTranslations');
    });
}

/**
 * Fetches a list of all areas from Realtime Database.
 * @returns {Promise<any>}
 */
function getAreasList() {
  debugTimeStart('getAreasList');
  return firebase
    .database()
    .ref('areasList')
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getAreasList');
    });
}

/**
 * Fetches data for a specific area by ID.
 * @param {string} areaId
 * @returns {Promise<any>}
 */
function getArea(areaId) {
  debugTimeStart('getArea');
  return firebase
    .database()
    .ref(`areas/${areaId}`)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getArea');
    });
}

/**
 * Gets customization data from the local cache if available;
 * otherwise, fetches from Realtime Database.
 * @param {string} customizationName
 * @returns {Promise<any>}
 */
function getCustomization(customizationName) {
  debugTimeStart('getCustomization');
  if (customizationObjects[customizationName]) {
    debugTimeEnd('getCustomization'); // End early if returning immediately
    return Promise.resolve(customizationObjects[customizationName]);
  } else {
    return firebase
      .database()
      .ref(`customizations/${customizationName}`)
      .once('value')
      .then((snapshot) => {
        customizationObjects[customizationName] = snapshot.val();
        return customizationObjects[customizationName];
      })
      .finally(() => {
        debugTimeEnd('getCustomization');
      });
  }
}

/**
 * Fetches boundaries for a given areaId.
 * @param {string} areaId
 * @returns {Promise<any>}
 */
function getBoundaries(areaId) {
  debugTimeStart('getBoundaries');
  return firebase
    .database()
    .ref(`boundaries/${areaId}`)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getBoundaries');
    });
}

/**
 * Fetches data for a specific layer by ID.
 * @param {string} layerId
 * @returns {Promise<any>}
 */
function getLayer(layerId) {
  debugTimeStart('getLayer');
  return firebase
    .database()
    .ref(`layers/${layerId}`)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getLayer');
    });
}

/**
 * Fetches all layers from Realtime Database.
 * @returns {Promise<any>}
 */
function getLayers() {
  debugTimeStart('getLayers');
  return firebase
    .database()
    .ref('layers')
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getLayers');
    });
}

/**
 * Fetches layer groups from Realtime Database.
 * @returns {Promise<any>}
 */
function getLayersGroups() {
  debugTimeStart('getLayersGroups');
  return firebase
    .database()
    .ref('layersGroups')
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getLayersGroups');
    });
}

/**
 * Fetches all scripts from Realtime Database.
 * @returns {Promise<any>}
 */
function getScripts() {
  debugTimeStart('getScripts');
  return firebase
    .database()
    .ref('scripts')
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getScripts');
    });
}

/**
 * Fetches metadata for scripts from Realtime Database.
 * @returns {Promise<any>}
 */
function getScriptsMetadata() {
  debugTimeStart('getScriptsMetadata');
  return firebase
    .database()
    .ref('scripts')
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getScriptsMetadata');
    });
}

/**
 * Fetches data for a single script by ID.
 * @param {string} scriptId
 * @returns {Promise<any>}
 */
function getScript(scriptId) {
  debugTimeStart('getScript');
  return firebase
    .database()
    .ref(`scripts/${scriptId}`)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getScript');
    });
}

/**
 * Gets a download URL from Firebase Storage for a given path.
 * @param {string} path
 * @returns {Promise<string>}
 */
function getDownloadUrl(path) {
  debugTimeStart('getDownloadUrl');
  return firebase
    .storage()
    .ref(path)
    .getDownloadURL()
    .finally(() => {
      debugTimeEnd('getDownloadUrl');
    });
}

/**
 * Adds a user boundary (a GeoJSON file) to Firebase Storage and Realtime Database.
 * @param {string} userId
 * @param {string} boundaryName
 * @param {Object} geoJson
 * @param {string} areaId
 * @param {string} agStackId
 * @returns {Promise<Object>} The boundary data, including boundaryId and geojsonSrc
 */
function addUserBoundary(userId, boundaryName, geoJson, areaId, agStackId) {
  debugTimeStart('addUserBoundary');
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const boundaryId = firebase.database().ref(`/users/${userId}/boundaries/`).push().key;
    const boundaryRef = `users/${userId}/boundaries/${boundaryId}.geojson`;
    const userRef = storageRef.child(boundaryRef);
    const file = new Blob([JSON.stringify(geoJson)], { type: 'application/json' });

    userRef
      .put(file)
      .then(() => {
        resolve({ boundaryId, geojsonSrc: boundaryRef });
        const boundary = {
          name: boundaryName,
          geojsonSrc: boundaryRef,
          areaId,
          agStackId: agStackId ? agStackId : '',
        };
        const updates = {};
        updates[`/users/${userId}/boundaries/${boundaryId}`] = boundary;
        firebase
          .database()
          .ref()
          .update(updates)
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  }).finally(() => {
    debugTimeEnd('addUserBoundary');
  });
}

/**
 * Loads user boundaries for a specific area.
 * @param {string} userId
 * @param {string} areaId
 * @returns {Promise<any>}
 */
function loadUserBoundaries(userId, areaId) {
  debugTimeStart('loadUserBoundaries');
  return firebase
    .database()
    .ref(`users/${userId}/boundaries`)
    .orderByChild('areaId')
    .equalTo(areaId)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('loadUserBoundaries');
    });
}

/**
 * Deletes a user boundary from Firebase Storage and removes the DB record.
 * @param {string} userId
 * @param {string} boundaryId
 * @returns {Promise<void>}
 */
function deleteUserBoundary(userId, boundaryId) {
  debugTimeStart('deleteUserBoundary');
  return new Promise((resolve, reject) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const userRef = storageRef.child(`users/${userId}/boundaries/${boundaryId}.geojson`);

    userRef
      .delete()
      .then(() => {
        firebase
          .database()
          .ref(`/users/${userId}/boundaries/${boundaryId}`)
          .remove()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  }).finally(() => {
    debugTimeEnd('deleteUserBoundary');
  });
}

/**
 * Sets user locale in the Realtime Database.
 * @param {string} userId
 * @param {string} locale
 */
function setUserLocale(userId, locale) {
  debugTimeStart('setUserLocale');
  try {
    firebase
      .database()
      .ref(`users/${userId}`)
      .set({ locale })
      .catch((error) => {
        throw error;
      });
  } finally {
    debugTimeEnd('setUserLocale');
  }
}

/**
 * Updates user options in the Realtime Database.
 * @param {string} userId
 * @param {Object} options
 * @returns {Promise<void>}
 */
function setUserOptions(userId, options) {
  debugTimeStart('setUserOptions');
  return firebase
    .database()
    .ref(`users/${userId}/config/userOptions`)
    .set(options)
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      debugTimeEnd('setUserOptions');
    });
}

/**
 * Gets user options from the Realtime Database.
 * @param {string} userId
 * @returns {Promise<any>}
 */
function getUserOptions(userId) {
  debugTimeStart('getUserOptions');
  return firebase
    .database()
    .ref(`users/${userId}/config/userOptions`)
    .once('value')
    .then((snapshot) => snapshot.val())
    .finally(() => {
      debugTimeEnd('getUserOptions');
    });
}

/**
 * Updates the Realtime Database to not show the splash dialog again for the user.
 * @param {string} userId
 */
function doNotShowSplashDialogAgain(userId) {
  debugTimeStart('doNotShowSplashDialogAgain');
  try {
    firebase
      .database()
      .ref(`users/${userId}/config/showSplashDialog/${process.env.REACT_APP_CUSTOMIZATION_NAME}`)
      .set(false)
      .catch((error) => {
        throw error;
      });
  } finally {
    debugTimeEnd('doNotShowSplashDialogAgain');
  }
}

/**
 * Determines whether the splash dialog should be shown for the user.
 * @param {string} userId
 * @returns {Promise<boolean>}
 */
function shouldShowSplashDialog(userId) {
  debugTimeStart('shouldShowSplashDialog');
  if (userId === null || userId === undefined) {
    debugTimeEnd('shouldShowSplashDialog'); // End early if returning immediately
    return true;
  }

  return firebase
    .database()
    .ref(`users/${userId}/config/showSplashDialog/${process.env.REACT_APP_CUSTOMIZATION_NAME}`)
    .once('value')
    .then((snapshot) => {
      const value = snapshot.val();
      // If null, the config doesn't exist; default to showing the splash dialog.
      return value === null ? true : value;
    })
    .finally(() => {
      debugTimeEnd('shouldShowSplashDialog');
    });
}

/**
 * Gets a GeoJSON file from Firebase Storage and retrieves its contents via Axios.
 * @param {string} boundaryId
 * @param {AbortController} abortController
 * @returns {Promise<Object>}
 */
async function getGeoJson(boundaryId, abortController) {
  debugTimeStart('getGeoJson');
  try {
    const url = await firebase.storage().ref(boundaryId).getDownloadURL();
    const response = await axios.get(url, { signal: abortController.signal });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.warn('Request canceled', error.message);
    } else {
      console.log('Error downloading GeoJSON', error.message);
      throw error;
    }
  } finally {
    debugTimeEnd('getGeoJson');
  }
}

/**
 * Fetches a custom config if available in ENV or from Realtime Database.
 * @returns {Promise<any>}
 */
async function getCustomizationConfig() {
  debugTimeStart('getCustomizationConfig');
  try {
    if (process.env.REACT_APP_CUSTOM_CONFIG) {
      return JSON.parse(process.env.REACT_APP_CUSTOM_CONFIG);
    } else if (process.env.REACT_APP_CUSTOMIZATION_NAME) {
      return await getCustomization(process.env.REACT_APP_CUSTOMIZATION_NAME);
    }
    return undefined;
  } finally {
    debugTimeEnd('getCustomizationConfig');
  }
}

export default {
  addUserBoundary,
  deleteUserBoundary,
  getArea,
  getAreasList,
  getAvailableLanguages,
  getBoundaries,
  getCustomizationConfig,
  getDownloadUrl,
  getGeoJson,
  getLayer,
  getLayers,
  getLayersGroups,
  getScripts,
  getScript,
  getScriptsMetadata,
  getTranslations,
  init,
  loadUserBoundaries,
  setUserLocale,
  signOut,
  doNotShowSplashDialogAgain,
  shouldShowSplashDialog,
  getUserOptions,
  setUserOptions,
  getUserAPIBearer,
};
