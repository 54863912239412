import { pushURLQuery } from 'router/UrlQueries';
import * as urlQueryFields from 'router/urlQueryFields';
import * as actionTypes from 'store/actions/actionTypes';
import * as actions from 'store/actions/index';
import AgStack from 'utils/AgStackAPI';
import { toast } from "react-toastify";
import { createFeatureCollectionFromJSON } from '../../utils/geojsonUtils';
import { getBoundaryInfo } from 'utils/boundaries';

const googleLoginModalShow = (enable) => ({
	type: actionTypes.SHOW_GOOGLE_LOGIN_MODAL,
	enable,
});

const modalDisclaimerShow = (enable) => ({
	type: actionTypes.SHOW_MODAL_DISCLAIMER,
	enable,
});

const drawerMenuToggle = (open) => ({
	type: actionTypes.DRAWER_MENU_TOGGLE,
	open,
});

export const showGoogleLoginModal = (enable) => (dispatch) => {
	dispatch(googleLoginModalShow(enable));
};

export const showModalDisclaimer = (enable) => (dispatch) => {
	dispatch(modalDisclaimerShow(enable));
};

export const toggleDrawerMenu = (open) => (dispatch) => {
	pushURLQuery(urlQueryFields.mainMenu, open);
	dispatch(drawerMenuToggle(open));
};


export const addGeoIds = function(geoIdsReceived, dispatch) {
	try {
		const agStack = new AgStack();
		const geoIds = geoIdsReceived.split(',');
		let plotIdx = 1;

		geoIds.forEach(geoId => {
			geoId = geoId.trim();
			if (geoId === "") return;
			agStack.getGeoJSON(geoId).then((response) => {
				const name = `Plot ${plotIdx} : ${geoId}`;
				const geoJson = { ...response, properties: { ...response.properties, geoId, name } };

				dispatch(actions.addTempBoundary(name, geoJson));
				if (plotIdx === 1) {
					dispatch(actions.addTempPolygon(name, geoJson));
					dispatch(actions.selectBoundaryId(name));
				}
				plotIdx++;

			}).catch((error) => {
				console.log(error);
				toast.error(
					`Geo ID ${geoId} not found. Please check for typos!`,
					{ autoClose: 10000 }
				);
			});
		})
	} catch (error) {
		console.error(error);
	}

}

export function fetchJson(url, dispatch) {
	// Remove double quotes from the URL
	url = url.replace(/"/g, '');

	// Use a relative URL for the API call
	let functionUrl = `/api/proxyFetch?url=${encodeURIComponent(url)}`;

	// Are we running in localhost?
	if (window.location.hostname === 'localhost') {
		// Specify the port 5000. In dev and prod the port is not specified
		functionUrl = 'http://localhost:5000' + functionUrl;
	}

	// Fetch the JSON data from the constructed URL
	fetch(functionUrl)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok ' + response.statusText);
			}
			response.json().then(jsonData => {
				const transformedGeojson = createFeatureCollectionFromJSON(jsonData); // Generate a uniform feature collection

				if (transformedGeojson.name) {
					dispatch(actions.setTempBoundariesName(transformedGeojson.name));
				}

				transformedGeojson.features.forEach(feature => {

					var name = getBoundaryInfo(feature).name;

					dispatch(actions.addTempBoundary(name, feature));
					// Only for the first feature, select it
					if (transformedGeojson.features.indexOf(feature) === 0) {
						dispatch(actions.addTempPolygon(name, feature));
						dispatch(actions.selectBoundaryId(name));
					}

				});
			}).catch(error => {
				console.warn('There has been a problem with your JSON parsing:', error);
				toast.warn(
					`The URL in fetchJson does not contain a valid GeoJSON!`,
					{ autoClose: 10000 }
				);
			})
		})
		.catch(error => {
			console.warn('There has been a problem with your fetch operation:', error);
			toast.warn(
				`The URL in fetchJson is not accessible or not allowed!`,
				{ autoClose: 10000 }
			);
		});
}

export function handlePolygonFromUrl(geoJson, unnamedArea, dispatch) {
	const transformedGeojson = createFeatureCollectionFromJSON(geoJson);

	if (transformedGeojson.name) {
		dispatch(actions.setTempBoundariesName(transformedGeojson.name));
	}
	transformedGeojson.features.forEach(feature => {

		var name = getBoundaryInfo(feature).name || unnamedArea;

		dispatch(actions.addTempBoundary(name, feature));
		// Only for the first feature, select it
		if (transformedGeojson.features.indexOf(feature) === 0) {
			dispatch(actions.addTempPolygon(name, feature));
			dispatch(actions.selectBoundaryId(name));
		}
	});
}

export const loadStateFromUrl = (urlQueries, unnamedArea) => (dispatch) => {
    const queryKeys = Object.keys(urlQueries);

    queryKeys.forEach((queryKey) => {
        switch (queryKey) {
            case urlQueryFields.aoi: {
                dispatch(actions.selectArea(urlQueries[queryKey]));
                break;
            }
            case urlQueryFields.boundary: {
                if (!queryKeys.includes(urlQueryFields.polygon)) {
                    dispatch(actions.selectBoundaryId(urlQueries[queryKey]));
                }
                break;
            }
            case urlQueryFields.geoId:
            case urlQueryFields.geoIds: {
                try {
                    addGeoIds(urlQueries[queryKey], dispatch);
                } catch (error) {
                    console.error(`Error processing geoId or geoIds: ${error.message}`, error);
                }
                break;
            }
            case urlQueryFields.fetchJson: {
                try {
                    fetchJson(urlQueries[queryKey], dispatch);
                } catch (error) {
                    console.error(`Error fetching JSON data: ${error.message}`, error);
                }
                break;
            }
            case urlQueryFields.polygon: {
                try {
                    const geoJson = JSON.parse(urlQueries[queryKey]);
                    handlePolygonFromUrl(geoJson, unnamedArea, dispatch);
                } catch (error) {
                    console.error(`Error parsing polygon JSON: ${error.message}`, error);
                }
                break;
            }
            // Uncomment and update this block if layers functionality is needed
            // case urlQueryFields.layers: {
            //     try {
            //         const layers = JSON.parse(urlQueries[queryKey]);
            //         Object.keys(layers).forEach((layerId) => {
            //             dispatch(actions.toggleLayer(layerId));
            //         });
            //     } catch (error) {
            //         console.error(`Error processing layers: ${error.message}`, error);
            //     }
            //     break;
            // }
            case urlQueryFields.scripts: {
                try {
                    const scripts = [];
                    const urlScripts = JSON.parse(urlQueries[queryKey]);
                    Object.keys(urlScripts).forEach((scriptId) => {
                        const { dateRange } = urlScripts[scriptId];
                        scripts.push({
                            id: scriptId,
                            timePeriod: urlScripts[scriptId].timePeriod,
                            dateRange: {
                                startYear: dateRange?.[0] || null,
                                endYear: dateRange?.[1] || null,
                            },
                        });
                    });
                    dispatch(actions.loadPreselectedScripts(scripts));
                } catch (error) {
                    console.error(`Error processing scripts: ${error.message}`, error);
                }
                break;
            }
            case urlQueryFields.mainMenu: {
                dispatch(actions.toggleDrawerMenu(urlQueries[queryKey] === 'true'));
                break;
            }
            case urlQueryFields.statisticsOpen: {
                dispatch(actions.openStatisticsDrawer(urlQueries[queryKey] === 'true'));
                break;
            }
            case urlQueryFields.lang: {
                dispatch(actions.setSelectedLanguage(urlQueries[queryKey]));
                break;
            }
            default: {
                console.warn(`Unhandled query key: ${queryKey}`);
                break;
            }
        }
    });
};

