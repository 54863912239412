import { DEFAULT_LOCALE } from 'components/App/AppConfig';

export default class LayerObject {
	constructor(immutableLayer, locale = DEFAULT_LOCALE) {
		this.availableBands = immutableLayer.get('AVAILABLE_BANDS');
		this.availableYears = immutableLayer.get('AVAILABLE_YEARS');
		this.bandSelectedDefault = immutableLayer.get('LANDCOVER_BAND');
		this.customGeoJsonVisualization = immutableLayer.get('customGeoJsonVisualization');
		this.dataSrc = immutableLayer.get('dataSrc');
		this.deprecated = immutableLayer.get('deprecated');
		this.description = immutableLayer.get('description');
		this.downloadablePng = immutableLayer.get('downloadablePng');
		this.downloadableTiff = immutableLayer.get('downloadableTiff');
		this.extraThresholdDefault = immutableLayer.get('extraThresholdDefault');
		this.extraThresholdOptions = immutableLayer.get('extraThresholdOptions');
		this.featureCollectionSrc = immutableLayer.get('featureCollectionSrc');
		this.featureViewSrc = immutableLayer.get('featureViewSrc');
		this.groupId = immutableLayer.get('group');
		this.id = immutableLayer.get('id');
		this.locale = locale;
		this.moreInfo = immutableLayer.get('moreInfo');
		this.name = immutableLayer.get('name');
		this.position = immutableLayer.get('position');
		this.relatedScriptId = immutableLayer.get('relatedScriptId');
		this.scriptSrc = immutableLayer.get('scriptSrc');
		this.src = immutableLayer.get('src');
		this.srcImageCollection = immutableLayer.get('srcImageCollection');
		this.thresholdDefault = immutableLayer.get('thresholdDefault');
		this.thresholdOptions = immutableLayer.get('thresholdOptions');
		this.type = immutableLayer.get('type');
		this.visualizeParams = immutableLayer.get('visualizeParams');
		this.wms = immutableLayer.get('wms');
		this.year = immutableLayer.get('year');

	}

	getName() {
		if (this.name && this.name.get(this.locale)) { return this.name.get(this.locale); }
		else if (this.name) { return this.name.get(DEFAULT_LOCALE); }
		else {
			console.warn("Missing Layer name for ID : " + this.id);
			return null;
		}
	}

	getType() {
		return this.type;
	}

	getGroupId() {
		return this.groupId;
	}

	getId() {
		return this.id;
	}

	getDataSrc() {
		if (this.dataSrc) {
			return {
				name: this.dataSrc.get('name') ? this.dataSrc.get('name') : undefined,
				link: this.dataSrc.get('link') ? this.dataSrc.get('link') : undefined,
			};
		} else {
			throw new Error("Missing Data Source data for ID : " + this.id + " name: " + this.name);
		}
	}

	getDescription() {
		return this.description;
	}
	
	getCustomGeoJsonVisualization() {
		return this.customGeoJsonVisualization;
	}

	getMoreInfo() {
		return this.moreInfo;
	}
	
	getScriptSrc() {
		return this.scriptSrc;
	}

	getSrc() {
		return this.src;
	}

	getSrcImageCollection() {
		return this.srcImageCollection;
	}
	
	getFeatureViewSrc() {
		return this.featureViewSrc;
	}
	
	getFeatureCollectionSrc() {
		return this.featureCollectionSrc;
	}
	getYear() {
		return this.year;
	}

	getWms() {
		return this.wms;
	}

	getVisualizeParams() {
		return this.visualizeParams;
	}


	isDeprecated() {
		return this.deprecated;
	}

	isPngDownloadable() {
		return (typeof this.downloadablePng === 'undefined') ? true : this.downloadablePng; // defaults to true so that we only need to specify the layers we want to mark as not downloadable in the layers JSON
	}

	isTiffDownloadable() {
		return (typeof this.downloadableTiff === 'undefined') ? true : this.downloadableTiff; // defaults to true so that we only need to specify the layers we want to mark as not downloadable in the layers JSON
	}

}
