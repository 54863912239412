import { StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, {
	lazy, Suspense, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import * as actions from 'store/actions/index';
import { componentLoader, RGB_Log_Shade } from 'utils/utils';
import useCustomConfig from 'hooks/useConfig';
import hexToRgba from 'hex-to-rgba';
import { getAdminStatus } from 'api/adminUtils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = lazy(() => componentLoader(() => import('components/Dashboard/Dashboard')));
const LegalDisclaimer = lazy(() => componentLoader(() => import('components/Disclaimers/Legal')));
const PrivacyDisclaimer = lazy(() => componentLoader(() => import('components/Disclaimers/Privacy')));
const Login = lazy(() => componentLoader(() => import('components/Login/Login')));
const AdminPage = lazy(() => componentLoader(() => import('components/Admin/Admin')));

function App() {
	const dispatch = useDispatch();
	const isSignedIn = useSelector((state) => state.user.get('authenticated'));
	const config = useCustomConfig();

	const location = useLocation();
	const [originalUrlParameter, setOriginalUrlParameters] = useState('');

	const mainColor = config && config.ui ? config.ui.primaryColor : '#6699cc';
	const secondaryColor = config && config.ui ? config.ui.secondaryColor : '#203b0e';

	const mainOpaqueColor = hexToRgba(mainColor, 0.5);
	const secondaryOpaqueColor = hexToRgba(secondaryColor, 0.5);

	useEffect(() => {
		setOriginalUrlParameters(location.search);
		dispatch(actions.loadAvailableLanguages());
	}, []);

	const anonymousSignIn = originalUrlParameter.includes('embed');

	const rootRedirect = () => {
		if (config === null) {
			return (<div />);
		} if (isSignedIn) {
			return (<Dashboard />);
		}
		return (<Redirect to="/login" />);
	};

	// eslint-disable-next-line react/prop-types
	function AdminRoute({ isSignedIn }) {
		const [isAdmin, setIsAdmin] = useState(null); // `null` indicates loading
		const [error, setError] = useState(false);

		useEffect(() => {
			const fetchAdminStatus = async () => {
				try {
					const adminStatus = await getAdminStatus();
					setIsAdmin(adminStatus);
				} catch (err) {
					console.error('Error checking admin status:', err);
					setError(true);
				}
			};

			if (isSignedIn) {
				fetchAdminStatus(); // Dynamically fetch admin status when the route is accessed
			}
		}, [isSignedIn]);

		if (!isSignedIn) {
			return <Redirect to="/login" />;
		}

		if (isAdmin === null) {
			return <div>Loading...</div>; // Show a loading spinner or placeholder
		}

		if (error || !isAdmin) {
			return <Redirect to="/" />;
		}

		return <AdminPage />;
	}

	// Material UI Theme
	const theme = React.useMemo(
		() => createTheme({
			spacing: 8,
			palette: {
				primary: {
					main: mainColor,
					mainOpaque: mainOpaqueColor
				},
				secondary: {
					main: secondaryColor,
					mainOpaque: secondaryOpaqueColor
				},
			},
			components: {
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							fontSize: "0.8em",
							color: RGB_Log_Shade(-0.7, hexToRgba(mainColor)),
							backgroundColor: RGB_Log_Shade(0.7, hexToRgba(secondaryColor)),
						}
					}
				},
				MuiInputBase: {
					styleOverrides: {
						root: {
							backgroundColor: 'white',
							'&.Mui-disabled': {
								backgroundColor: '#f5f5f5',
							},
						},
					},
				},
			}
		}),
		[config],
	);

	// HANDLE RESIZE OF WINDOW GLOBALLY
	// This is used to detect if we're on a mobile or desktop size window
	// and also handles resizing of the window to set the isMobile variable in the global state
	useEffect(() => {
		const handleResize = () => {
			const isMobile = window.innerWidth <= theme.breakpoints.values.md;
			dispatch(actions.setIsMobile(isMobile));
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch, theme.breakpoints.values.md]);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
				<Suspense fallback={<div />}>
					<Switch>
						<Route path="/legal" component={LegalDisclaimer} />
						<Route path="/privacy" component={PrivacyDisclaimer} />
						<Route
							path="/login"
							render={() => (
								!isSignedIn && config !== null
									? <Login anonymous={anonymousSignIn} />
									: (
										<Redirect to={{
											pathname: '/',
											search: originalUrlParameter,
										}}
										/>
									))}
						/>
						<Route path="/admin" render={() => <AdminRoute isSignedIn={isSignedIn} />} />
						<Route path="/" render={rootRedirect} />
					</Switch>
				</Suspense>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
