import { push } from 'connected-react-router';
import store from 'index';
import queryString from 'query-string';
import * as urlQueryFields from 'router/urlQueryFields';

let init = false;

export function pushURLQuery(queryKey, queryValue) {
  if (init) {
    // Use setTimeout to avoid updating during render
    setTimeout(() => {
      const urlQuery = queryString.parse(store.getState().router.location.search);
      urlQuery[queryKey] = queryValue;
      store.dispatch(push(`?${queryString.stringify(urlQuery)}`));
    }, 0);
  }
}

export function getURLQueries(queryField) {
  const queries = queryString.parse(store.getState().router.location.search);
  try {
    if (queryField === urlQueryFields.layers) init = true;
    if (!queries[queryField]) init = true;
    if (!queryField) { return queries; }
    if (queries[queryField]) return JSON.parse(queries[queryField]);
  } catch (error) {
    console.error(error, queryField, queries[queryField]);
  }
  return null;
}

export { urlQueryFields };

export default { pushURLQuery, getURLQueries, urlQueryFields };
