import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
// Import debugTime utilities
import { debugTimeStart, debugTimeEnd } from 'utils/utils';

/** 
 * DRY Helper: callFirebaseFunction 
 * - Minimizes repetitive code for each callable function.
 * - Logs and rethrows errors for consistent error handling.
 */
async function callFirebaseFunction(functionName, data = {}) {
  // Start timing callFirebaseFunction
  debugTimeStart('callFirebaseFunction ' + functionName);
  try {
    const callable = firebase.functions().httpsCallable(functionName, { timeout: 60000 });
    const response = await callable(data);
    return response.data;
  } catch (error) {
    console.error(`Error in callable "${functionName}":`, error);
    throw error;
  } finally {
    // End timing callFirebaseFunction
    debugTimeEnd('callFirebaseFunction ' + functionName);
  }
}

// Reusable reference for customizationName
const CUSTOMIZATION_NAME = process.env.REACT_APP_CUSTOMIZATION_NAME || 'default';

/**
 * Checks if the current user is an admin for the current customization
 * @returns {Promise<boolean>} Resolves to true if the user is an admin, false otherwise.
 */
export async function getAdminStatus() {
  // Start timing getAdminStatus
  debugTimeStart('getAdminStatus');
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) return false;

    const data = await callFirebaseFunction('checkAdmin', {
      customizationName: CUSTOMIZATION_NAME,
    });
    return data?.isAdmin === true;
  } finally {
    // End timing getAdminStatus
    debugTimeEnd('getAdminStatus');
  }
}

/**
 * Updates the customization configuration in the backend.
 * @param {Object} config The updated configuration
 * @returns {Promise<boolean>} Resolves to true if the update is successful
 */
export async function updateCustomizationConfig(config) {
  // Start timing updateCustomizationConfig
  debugTimeStart('updateCustomizationConfig');
  try {
    if (!config) {
      throw new Error('Invalid request: config is required.');
    }

    const data = await callFirebaseFunction('updateCustomizationConfig', {
      customizationName: CUSTOMIZATION_NAME,
      config,
    });
    return data?.success === true;
  } finally {
    // End timing updateCustomizationConfig
    debugTimeEnd('updateCustomizationConfig');
  }
}

/**
 * Function to update a boundary in the backend
 * @param {string} aoiId
 * @param {string} boundaryId
 * @param {Object} updatedBoundary
 * @returns {Promise<any>} - The response data from the backend
 */
export async function updateBoundary(aoiId, boundaryId, updatedBoundary) {
  // Start timing updateBoundary
  debugTimeStart('updateBoundary');
  try {
    return await callFirebaseFunction('updateBoundary', {
      customizationName: CUSTOMIZATION_NAME,
      aoiId,
      boundaryId,
      updatedBoundary,
    });
  } finally {
    // End timing updateBoundary
    debugTimeEnd('updateBoundary');
  }
}

/**
 * Function to update a GeoJSON boundary file in the backend
 * @param {string} filePath 
 * @param {Object} geoJsonContent
 * @returns {Promise<any>} - The response data from the backend
 */
export async function updateGeoJsonBoundary(filePath, geoJsonContent) {
  // Start timing updateGeoJsonBoundary
  debugTimeStart('updateGeoJsonBoundary');
  try {
    return await callFirebaseFunction('updateBoundaryGeoJson', {
      customizationName: CUSTOMIZATION_NAME,
      filePath,
      geoJsonContent,
    });
  } finally {
    // End timing updateGeoJsonBoundary
    debugTimeEnd('updateGeoJsonBoundary');
  }
}

/**
 * Function to delete a boundary in the backend
 * @param {string} aoiId
 * @param {string} boundaryId
 * @param {string} geojsonFilePath
 * @returns {Promise<void>}
 */
export async function deleteBoundary(aoiId, boundaryId, geojsonFilePath) {
  // Start timing deleteBoundary
  debugTimeStart('deleteBoundary');
  try {
    const data = await callFirebaseFunction('deleteBoundary', {
      customizationName: CUSTOMIZATION_NAME,
      aoiId,
      boundaryId,
      geojsonFilePath,
    });
    if (!data?.success) {
      throw new Error(`Failed to delete boundary: ${data?.message || 'Unknown error'}`);
    }
  } finally {
    // End timing deleteBoundary
    debugTimeEnd('deleteBoundary');
  }
}

/**
 * Function to upload an image to Firebase Storage using the backend function.
 * @param {File} imageFile - The image file to upload.
 * @param {string} filePath - Desired path for storing the image in Firebase Storage.
 * @returns {Promise<string>} - The public URL of the uploaded image.
 */
export async function uploadImage(imageFile, filePath) {
  // Start timing uploadImage
  debugTimeStart('uploadImage');
  try {
    if (!imageFile) throw new Error('No image file provided.');

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.onload = async () => {
        try {
          const base64Image = reader.result.split(',')[1];
          const data = await callFirebaseFunction('uploadImage', {
            customizationName: CUSTOMIZATION_NAME,
            filePath,
            imageBuffer: base64Image,
            mimeType: imageFile.type,
          });
          resolve(data?.imageUrl);
        } catch (err) {
          console.error('Error uploading image:', err);
          reject(err);
        }
      };

      reader.onerror = (err) => {
        console.error('Error reading image file:', err);
        reject(err);
      };
    });
  } finally {
    // End timing uploadImage
    debugTimeEnd('uploadImage');
  }
}

/**
 * Function to add a layer to an AOI
 * @param {string} aoiId
 * @param {string} layerId
 * @returns {Promise<any>}
 */
export async function addLayerToArea(aoiId, layerId) {
  // Start timing addLayerToArea
  debugTimeStart('addLayerToArea');
  try {
    return await callFirebaseFunction('addLayerToArea', {
      customizationName: CUSTOMIZATION_NAME,
      aoiId,
      layerId,
    });
  } finally {
    // End timing addLayerToArea
    debugTimeEnd('addLayerToArea');
  }
}

/**
 * Function to remove a layer from an AOI
 * @param {string} aoiId
 * @param {string} layerId
 * @returns {Promise<any>}
 */
export async function removeLayerFromArea(aoiId, layerId) {
  // Start timing removeLayerFromArea
  debugTimeStart('removeLayerFromArea');
  try {
    return await callFirebaseFunction('removeLayerFromArea', {
      customizationName: CUSTOMIZATION_NAME,
      aoiId,
      layerId,
    });
  } finally {
    // End timing removeLayerFromArea
    debugTimeEnd('removeLayerFromArea');
  }
}

/**
 * Function to add a script to an AOI
 * @param {string} aoiId
 * @param {string} scriptId
 * @returns {Promise<any>}
 */
export async function addScriptToArea(aoiId, scriptId) {
  // Start timing addScriptToArea
  debugTimeStart('addScriptToArea');
  try {
    return await callFirebaseFunction('addScriptToArea', {
      customizationName: CUSTOMIZATION_NAME,
      aoiId,
      scriptId,
    });
  } finally {
    // End timing addScriptToArea
    debugTimeEnd('addScriptToArea');
  }
}

/**
 * Function to remove a script from an AOI
 * @param {string} aoiId
 * @param {string} scriptId
 * @returns {Promise<any>}
 */
export async function removeScriptFromArea(aoiId, scriptId) {
  // Start timing removeScriptFromArea
  debugTimeStart('removeScriptFromArea');
  try {
    return await callFirebaseFunction('removeScriptFromArea', {
      customizationName: CUSTOMIZATION_NAME,
      aoiId,
      scriptId,
    });
  } finally {
    // End timing removeScriptFromArea
    debugTimeEnd('removeScriptFromArea');
  }
}

/**
 * Fetch info about a GEE asset
 * @param {string} geeAssetLink
 * @returns {Promise<Object>}
 */
export async function getGEEAssetInfo(geeAssetLink) {
  // Start timing getGEEAssetInfo
  debugTimeStart('getGEEAssetInfo');
  try {
    return await callFirebaseFunction('getGEEAssetInfo', {
      customizationName: CUSTOMIZATION_NAME,
      geeAssetLink,
    });
  } finally {
    // End timing getGEEAssetInfo
    debugTimeEnd('getGEEAssetInfo');
  }
}

/**
 * Add a layer to the DB
 * @param {string} layerId
 * @param {Object} layerData
 * @returns {Promise<any>}
 */
export async function addLayerToDb(layerId, layerData) {
  // Start timing addLayerToDb
  debugTimeStart('addLayerToDb');
  try {
    return await callFirebaseFunction('addLayer', {
      customizationName: CUSTOMIZATION_NAME,
      layerId,
      layerData,
    });
  } finally {
    // End timing addLayerToDb
    debugTimeEnd('addLayerToDb');
  }
}

/**
 * Get all areas with a given layerId
 * @param {string} layerId
 * @returns {Promise<any>}
 */
export async function getAreasWithLayerId(layerId) {
  // Start timing getAreasWithLayerId
  debugTimeStart('getAreasWithLayerId');
  try {
    return await callFirebaseFunction('getAreasWithLayerId', {
      customizationName: CUSTOMIZATION_NAME,
      layerId,
    });
  } finally {
    // End timing getAreasWithLayerId
    debugTimeEnd('getAreasWithLayerId');
  }
}

/**
 * Remove a layer from the DB
 * @param {string} layerId
 * @returns {Promise<any>}
 */
export async function removeLayerFromDb(layerId) {
  // Start timing removeLayerFromDb
  debugTimeStart('removeLayerFromDb');
  try {
    return await callFirebaseFunction('removeLayer', {
      customizationName: CUSTOMIZATION_NAME,
      layerId,
    });
  } finally {
    // End timing removeLayerFromDb
    debugTimeEnd('removeLayerFromDb');
  }
}
