export function getFeatureName(properties) {
  const STANDARD_NAMES = ['adm2_name', 'adm2nm', 'adm1_name', 'adm1nm','adm0_name', 'name', 'nombre', 'nom', 'romnam', 'admin_name', 'admin', 'project name'];
  const propertiesArray = Object.getOwnPropertyNames(properties);
  let possibleNames = propertiesArray
    .map(((propertyName) => propertyName.toLowerCase()))
    .filter((propertyName) => STANDARD_NAMES.includes(propertyName))
    .sort((a, b) => STANDARD_NAMES.indexOf(a) - STANDARD_NAMES.indexOf(b));
	
	if (possibleNames.length === 0) {
		possibleNames = propertiesArray
		    .map(((propertyName) => propertyName.toLowerCase()))
			// filter is at least part of the propertyName is in the STANDARD_NAMES array
		    .filter( (propertyName) => STANDARD_NAMES.some((standardName) => propertyName.includes(standardName)	) )
		    .sort((a, b) => STANDARD_NAMES.indexOf(a) - STANDARD_NAMES.indexOf(b));
	}
	
  return properties[propertiesArray.find(
    (property) => property.toLowerCase() === possibleNames[0],
  )];
}

export function getBoundaryInfo(boundary) {
  if (boundary && boundary.properties) {
    const featureName = getFeatureName(boundary.properties);
    const { population } = boundary.properties;
    const elevation = boundary.properties.avg_elevation;
    const { area } = boundary.properties;
    return {
      name: featureName, area, population, elevation,
    };
  }
  return {};
}